import React from "react";
import Nicholas from "assets/Nicholas.png";

// form & validsation
import * as yup from "yup";
import { FormError, FormRadio, LM } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { customClasses } from "styles";

// schema
const Step11schema = yup.object().shape({
  if_joint_divorce: yup
    .string()
    .required("This is a required field")
    .nullable(),
});
export function Step11({ onSubmit, store }) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: store,
    resolver: yupResolver(Step11schema),
  });
  return (
    <form id="divorce-form" onSubmit={handleSubmit(onSubmit)}>
      <section>
        <img
          src={Nicholas}
          className="w-20 h-20 m-auto border-blue-200 border-2 rounded-full"
          alt="user"
        />

        <p className={customClasses.questionText}>
          Will you and {store?.ex_first_name} be lodging a joint divorce
          application?
        </p>
        <LM>
          <p className={customClasses.helperText}>
            A joint divorce is when you and {store?.ex_first_name} both sign a
            divorce application together. We will only act for you but we can
            obtain information from {store?.ex_first_name}.
          </p>
          <p className={customClasses.helperText}>
            You both need to be amicable to file a joint divorce.
          </p>

          <p className={customClasses.helperText}>
            If {store?.ex_first_name} is unwilling to sign the divorce
            application, you can still file the divorce yourself and then we
            will provide a copy of the application to your partner (but select
            no for this question).
          </p>
        </LM>
        <FormRadio
          setValue={setValue}
          value="Yes"
          name="if_joint_divorce"
          register={register}
        />
        <FormRadio
          setValue={setValue}
          value="No"
          name="if_joint_divorce"
          register={register}
        />

        <FormRadio
          setValue={setValue}
          value="Unsure"
          name="if_joint_divorce"
          register={register}
        />
        <FormError error={errors.if_joint_divorce?.message} />
      </section>
    </form>
  );
}
