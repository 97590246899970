import React from "react";
import Nicholas from "assets/Nicholas.png";

// form & validsation
import * as yup from "yup";
import { FormError, FormRadio } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { customClasses } from "styles";

// schema
const Step16schema = yup.object().shape({
  ex_if_post_respond: yup
    .string()
    .required("This is a required field")
    .nullable(),
});

export function Step16({ onSubmit, store }) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: store,
    resolver: yupResolver(Step16schema),
  });
  return (
    <form id="divorce-form" onSubmit={handleSubmit(onSubmit)}>
      <section>
        <img
          src={Nicholas}
          className="w-20 h-20 m-auto border-blue-200 border-2 rounded-full"
          alt="user"
        />

        <p className={customClasses.questionText}>
          If we post the divorce documents, is there a good chance that{" "}
          {store?.ex_first_name} will sign them and send it back to us?
        </p>
        <FormRadio
          setValue={setValue}
          value="Yes"
          name="ex_if_post_respond"
          register={register}
        />
        <FormRadio
          setValue={setValue}
          value="No"
          name="ex_if_post_respond"
          register={register}
        />
        <FormRadio
          setValue={setValue}
          value="Unsure"
          name="ex_if_post_respond"
          register={register}
        />
        <FormError error={errors?.ex_if_post_respond?.message} />
      </section>
    </form>
  );
}
