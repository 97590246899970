import React, { useState } from "react";
import { formSteps } from "./Steps";
import { Button } from "components";
import { HeaderNavigation } from "components";
import {
  saveToFirebase,
  sendMkiEmail,
  sendUserConfirmationEmail,
} from "services";
import { useToggle } from "hooks";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { handleDataTransform } from "utils";
import { Modal } from "components/Basic/Modal";
import dayjs from "dayjs";

// starting values. Everything empty
const intialValues = {
  ex_first_name: "",
  ex_last_name: "",
  marriage_location: "",
  get_married: "",
  get_separated: "",
  marriage_less2_if_counselling: "",
  reconciliation_counselling: "",
  marriage_less2_counselling_excuse: "",
  marriage_cert_if_true: "",
  marriage_cert_if_english: "",
  children_if_true: "",
  if_joint_divorce: "",
  ex_address_if_known: "",
  ex_address_if_overseas: "",
  ex_if_contact_true: "",
  ex_if_cooperation: "",
  ex_if_post_respond: "",
  details: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  },
  lived_apart_12m_if_true: "",
};

const FormNavigationButtons = ({
  isLast,
  isFirst,
  handleBackClick,
  onStartOver,
}) => {
  return (
    <section className={`flex flex-col h-12 items-center mt-2`}>
      {isLast ? (
        <Button
          type="submit"
          fontSize="lg"
          form="divorce-form"
          className="w-11/12 md:w-full md:max-w-xs mx-3 md:mx-0 focus:outline-none p-4 bg-native-200 hover:bg-native-300  hover:border-native-300"
        >
          Get A Quote
        </Button>
      ) : (
        <Button
          type="submit"
          fontSize="lg"
          form="divorce-form"
          className="w-11/12 md:w-full md:max-w-xs mx-3 md:mx-0 p-4 focus:outline-none bg-native-200 hover:bg-native-300  hover:border-native-300"
        >
          Next
        </Button>
      )}
      {isFirst === false && (
        <div className=" italic mt-10 cursor-pointer" onClick={onStartOver}>
          <p> Start Over</p>
        </div>
      )}
    </section>
  );
};

export function DivorceForm() {
  // this state keeps track what step the form is up to.
  const [currentStep, setCurrentStep] = React.useState(0);
  const CurrentTab = formSteps[currentStep];

  // this is used to keep track of the form data that is saved
  const [formState, setFormState] = useState(intialValues);

  // these are just for toggles on the page
  const { toggleOn, onToggleClick } = useToggle();
  const { toggleOn: startOver, onToggleClick: onStartOver } = useToggle();
  const { toggleOn: redirect, onToggleClick: onRedirect } = useToggle();

  const isLastStep = () => currentStep === Object.keys(formSteps).length - 1;
  const isFirstStep = () => currentStep === 0 || currentStep === 1;

  // all the logic jumps is here.
  const handleDataTransform = (data) => {
    const [stepName] = Object.keys(data);
    console.log(data);

    if (data["get_separated"] !== "" && currentStep === 4) {
      const diff = dayjs(data.get_separated).diff(
        dayjs(formState.get_married),
        "day"
      );
      if (diff > 730) setCurrentStep((prevStep) => prevStep + 3);
    }
    if (data["marriage_less2_if_counselling"] === "Yes" && currentStep === 5)
      setCurrentStep((prevStep) => prevStep + 2);

    // if (data["reconciliation_counselling"] === "Yes" && currentStep === 6) {
    //   onRedirect();
    // }

    return data;
  };

  const onSubmit = (formData) => {
    setFormState((prevState) => ({ ...prevState, ...formData }));
    const data = handleDataTransform(formData);
    console.log("data", data);
    if (isLastStep()) {
      //does firebase data dump. This has 3rd param to trigger the toggle. I disabled it.
      saveToFirebase(data, setCurrentStep);

      // sends email to mki legal
      sendMkiEmail({
        userEmail: data?.details?.email,
        userFirstName: data?.details?.first_name,
        userLastName: data?.details?.last_name,
      });

      // sends confirmation email to user

      sendUserConfirmationEmail({
        userEmail: data?.details?.email,
        userFirstName: data?.details?.first_name,
        userLastName: data?.details?.last_name,
      });
      //resets form with empty values
      setFormState(intialValues);
      setTimeout(
        () =>
          (window.location.href =
            "https://www.familylawassist.com.au/divorce-quote-thank-you/"),
        1000
      );
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };
  const isLast = isLastStep();
  const isFirst = isFirstStep();

  const handleBackClick = (event) => {
    event.preventDefault();
    if (currentStep > 0 && currentStep <= Object.keys(formSteps).length)
      setCurrentStep((prevStep) => prevStep - 1);
  };
  const resetForm = () => {
    setFormState(intialValues);
    setCurrentStep(0);
    onStartOver();
  };
  return (
    <div className="h-screen relative">
      <HeaderNavigation onStartOver={onStartOver} />
      <section className="mx-auto w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 pt-16">
        {/* Main Section */}
        {/* <section className="grid-rows-6 grid gap-y-10 md:gap-y-4"> */}
        <section className="px-2 md:px-20 pt-10">
          {/* important. FormState is empty initially. It is updated on each next press and returning it the updated object.  */}
          <CurrentTab onSubmit={onSubmit} store={formState} />
        </section>
        <FormNavigationButtons
          isLast={isLast}
          isFirst={isFirst}
          handleBackClick={handleBackClick}
          onStartOver={onStartOver}
        />
        {/* </section> */}
      </section>
      <footer className="self-center mt-40 my-20">
        <p className="text-center">
          Liability Limited by a scheme approved under Professional Standards
          Legislation.
        </p>
      </footer>

      {/* <Modal onToggleClick={onToggleClick} toggleOn={toggleOn}>
        <p className="text-center text-3xl">Success</p>
        <p className="text-center">
          Thank you, we have received your information.
        </p>
        <p className="text-center">We will email you a quote shortly.</p>
        <div className="flex items-center justify-center mt-6	 ">
          <Button
            onClick={(e) => {
              setFormState(intialValues);
              setCurrentStep(0);
              e.preventDefault();
              window.location.href = "https://www.familylawassist.com.au/";
            }}
            className=" w-5/12 font-medium mx-3 md:mx-0 p-4 bg-native-200 hover:bg-native-300 shadow-lg border-native-200"
          >
            OKAY
          </Button>
        </div>
      </Modal> */}

      {/* <Modal onToggleClick={onRedirect} toggleOn={redirect}>
        <div className="flex flex-col w-full">
          <p className="text-center text-2xl">You Must Attend Counselling</p>

          <p className="text-left">
            For marriages lasting less than 2 years, the court requires both
            people to attend reconciliation counselling, just to make sure that
            reconciliation is not possible.
          </p>

          <p>
            After the reconciliation counselling session, if you both still
            decide that the relationship is over, the counsellor will issue a
            certificate to confirm you both attended the counselling session.{" "}
          </p>

          <p className="text-left">
            You can apply for divorce after you get this counselling certificate{" "}
          </p>

          <p className="text-left">
            You need to attend counselling unless you have a good reason.
          </p>

          <section className="w-full flex justify-around mt-5">
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.location.href =
                  "https://www.familycourt.wa.gov.au/A/application_married_for_less_than_two_years.aspx";
              }}
              className="w-5/12  mx-3 md:mx-0 p-4 bg-gray-100 hover:bg-gray-200 font-medium text-gray-600 hover:text-gay-700 border-gray-200"
            >
              MORE INFORMATION
            </Button>
            <Button
              onClick={() => {
                setFormState(intialValues);
                setCurrentStep(0);
                onRedirect();
              }}
              className="w-5/12 font-medium mx-3 md:mx-0 p-4 bg-native-200 hover:bg-native-300 shadow-lg border-native-200"
            >
              RESTART QUESTIONS
            </Button>
          </section>
        </div>
      </Modal> */}

      <Modal onToggleClick={onStartOver} toggleOn={startOver}>
        <p className="text-center text-xl font-medium -mt-6">
          WANT TO START OVER?
        </p>
        <p className="text-center text-lg">
          This will clear your answers and start again from the beginning
        </p>
        <section className="w-full flex justify-around mt-5">
          <Button
            onClick={onStartOver}
            className="w-5/12  mx-3 md:mx-0 p-4 bg-gray-100 hover:bg-gray-200 font-medium text-gray-600 hover:text-gay-700 border-gray-200"
          >
            CANCEL
          </Button>
          <Button
            onClick={resetForm}
            className="w-5/12 font-medium mx-3 md:mx-0 p-4 bg-native-200 hover:bg-native-300 shadow-lg border-native-200"
          >
            START OVER
          </Button>
        </section>
      </Modal>
    </div>
  );
}
