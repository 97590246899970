import React from "react";
import Nicholas from "assets/Nicholas.png";

// form & validsation
import * as yup from "yup";
import { FormError, FormRadio, LM } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { customClasses } from "styles";

// schema
const Step05schema = yup.object().shape({
  marriage_less2_if_counselling: yup
    .string()
    .required("This is a required field")
    .nullable(),
});
export function Step05({ onSubmit, store }) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: store,
    resolver: yupResolver(Step05schema),
  });
  return (
    <form id="divorce-form" onSubmit={handleSubmit(onSubmit)}>
      <section>
        <img
          src={Nicholas}
          className="w-20 h-20 m-auto border-blue-200 border-2 rounded-full"
          alt="user"
        />
        <p className={customClasses.questionText}>
          Have you attended reconciliation counselling and obtained a
          certificate?
        </p>

        <LM>
          <p className={customClasses.helperText}>
            For marriages lasting less than 2 years, the court requires both
            people to attend reconciliation counselling, just to make sure that
            reconciliation is not possible.
          </p>
          <p className={customClasses.helperText}>
            After the reconciliation counselling session, if you both still
            decide that the relationship is over, the counsellor will issue a
            certificate to confirm you both attended the counselling session.
          </p>
          <p className={customClasses.helperText}>
            You need this counselling certificate to apply for a divorce because
            your marriage was less than 2 years. However, if you can’t get the
            counselling certificate, and you have good reason, you may be exempt
            from getting this. We will discuss this more in later questions.
          </p>
        </LM>
        <FormRadio
          setValue={setValue}
          value="Yes"
          name="marriage_less2_if_counselling"
          register={register}
        />
        <FormRadio
          setValue={setValue}
          value="No"
          name="marriage_less2_if_counselling"
          register={register}
        />

        <FormError error={errors.marriage_less2_if_counselling?.message} />
      </section>
    </form>
  );
}
