import React from "react";
import { Hamburger, Button, Call } from "components";
import Logo from "assets/logo.png";
import { Drawer } from "antd";
import { useToggle } from "hooks";
import ReplayIcon from "@material-ui/icons/Replay";

export function HeaderNavigation({ onStartOver }) {
  const { toggleOn, onToggleClick } = useToggle();
  return (
    <>
      <div className="w-full h-20 bg-white shadow-md flex justify-between items-center px-3 fixed z-10">
        <div className="h-full flex items-center justify-between">
          <button onClick={onToggleClick}>
            <Hamburger
              width={25}
              height={25}
              fillColor="#333"
              strokeWidth={50}
            />
          </button>
          <img src={Logo} className="pl-4 max-w-xs h-10 ml-2" alt="logo" />
        </div>

        <div
          onClick={onStartOver}
          className="hidden md:block bg-transparent cursor-pointer px-2 sm:px-4 py-2 h-8 sm:h-10 flex items-center justify-around absolute right-44"
        >
          <ReplayIcon className="text-native-300" />
        </div>
        <Button
          type="button"
          borderColor="yellow-500"
          className="px-2 sm:px-4 py-2 shadow-sm h-8 sm:h-10 flex items-center justify-around"
          bgColor="bg-yellow-500"
          textColor="white"
          onClick={() => window.open("tel:+61 1300 967 552", "_self")}
        >
          <Call fillColor="#ffffff" width={15} height={15} /> &nbsp;1300 967 552
        </Button>
      </div>
      <Drawer
        title="MKI Legal"
        placement="left"
        closable={false}
        onClose={onToggleClick}
        visible={toggleOn}
      >
        <div className="text-lg text-medium p-3 cursor-pointer hover:text-native-300">
          <a href="https://www.familylawassist.com.au/"> Home</a>
        </div>
        <div
          className="text-lg text-medium p-3 cursor-pointer hover:text-native-300"
          onClick={() => {
            onToggleClick();
            onStartOver();
          }}
        >
          Start Over
        </div>
        <div className="mt-20">
          <p className="text-center ">
            Liability Limited by a scheme approved under Professional Standards
            Legislation.
          </p>
        </div>
      </Drawer>
    </>
  );
}
