import React from "react";
import Nicholas from "assets/Nicholas.png";

// form & validsation
import * as yup from "yup";
import { FormError, FormRadio } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { customClasses } from "styles";

// schema
const Step12schema = yup.object().shape({
  ex_address_if_known: yup
    .string()
    .required("This is a required field")
    .nullable(),
});
export function Step12({ onSubmit, store }) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: store,
    resolver: yupResolver(Step12schema),
  });
  return (
    <form id="divorce-form" onSubmit={handleSubmit(onSubmit)}>
      <section>
        <img
          src={Nicholas}
          className="w-20 h-20 m-auto border-blue-200 border-2 rounded-full"
          alt="user"
        />

        <p className={customClasses.questionText}>
          Do you know {store?.ex_first_name}'s current residential address?
        </p>
        <FormRadio
          setValue={setValue}
          value="Yes"
          name="ex_address_if_known"
          register={register}
        />
        <FormRadio
          setValue={setValue}
          value="No"
          name="ex_address_if_known"
          register={register}
        />
        <FormError error={errors.ex_address_if_known?.message} />
      </section>
    </form>
  );
}
