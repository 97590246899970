import React from "react";
import Nicholas from "assets/Nicholas.png";

// form & validsation
import * as yup from "yup";
import { FormError, FormRadio } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { customClasses } from "styles";

// schema
const Step17schema = yup.object().shape({
  lived_apart_12m_if_true: yup
    .string()
    .required("This is a required field")
    .nullable(),
});

export function Step17({ onSubmit, store }) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: store,
    resolver: yupResolver(Step17schema),
  });
  return (
    <form id="divorce-form" onSubmit={handleSubmit(onSubmit)}>
      <section>
        <img
          src={Nicholas}
          className="w-20 h-20 m-auto border-blue-200 border-2 rounded-full"
          alt="user"
        />

        <p className={customClasses.questionText}>
          Did you and {store?.ex_first_name} live apart in separate houses for
          at least 12 months since you separated?
        </p>
        <FormRadio
          setValue={setValue}
          value="Yes"
          name="lived_apart_12m_if_true"
          register={register}
        />
        <FormRadio
          setValue={setValue}
          value="No"
          name="lived_apart_12m_if_true"
          register={register}
        />
        <FormError error={errors?.lived_apart_12m_if_true?.message} />
      </section>
    </form>
  );
}
