import React from 'react';
import Nicholas from 'assets/Nicholas.png';

// form & validsation
import * as yup from 'yup';
import { FormError, FormRadio } from 'components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { customClasses } from 'styles';

// schema
const Step02schema = yup.object().shape({
	marriage_location: yup.string().required('This is a required field').nullable()
});
export function Step02({ onSubmit, store }) {
	const {
		handleSubmit,
		register,
		setValue,
		formState: { errors }
	} = useForm({
		defaultValues: store,
		resolver: yupResolver(Step02schema)
	});
	return (
		<form id="divorce-form" onSubmit={handleSubmit(onSubmit)}>
			<section>
				<img src={Nicholas} className="w-20 h-20 m-auto border-blue-200 border-2 rounded-full" alt="user" />
				<p className={customClasses.questionText}>Where did you get married?</p>
				<FormRadio setValue={setValue} value="Australia" name="marriage_location" register={register} />
				<FormRadio setValue={setValue} value="Overseas" name="marriage_location" register={register} />
				<FormError error={errors.marriage_location?.message} />
			</section>
		</form>
	);
}
