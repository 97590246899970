import { Step00 } from "./Step00";
import { Step01 } from "./Step01";
import { Step02 } from "./Step02";
import { Step03 } from "./Step03";
import { Step04 } from "./Step04";
import { Step05 } from "./Step05";
import { Step06 } from "./Step06";
import { Step07 } from "./Step07";
import { Step08 } from "./Step08";
import { Step09 } from "./Step09";
import { Step10 } from "./Step10";
import { Step11 } from "./Step11";
import { Step12 } from "./Step12";
import { Step13 } from "./Step13";
import { Step14 } from "./Step14";
import { Step15 } from "./Step15";
import { Step16 } from "./Step16";
import { Step17 } from "./Step17";
import { Step18 } from "./Step18";

export const formSteps = {
  0: Step00, //intro
  1: Step01, //ex-partner's name
  2: Step02, // where married (if overseas)
  3: Step03, // when married
  4: Step04, // when separated
  5: Step05, // obtained counseling certificate
  6: Step06, // reconciliation counselling feasible
  7: Step07, // why not reasonable
  8: Step08, // copy marriage certificate
  9: Step09, // certificate in english
  10: Step10, // children under 18 years
  11: Step11, // joint divorce
  12: Step12, // ex partner address known
  13: Step13, // ex partner lives overseas
  14: Step14, // contact ex in some way
  15: Step15, // ex provide information
  16: Step16, // ex sign ackowledgement
  17: Step17, // apart in separate houses 12months
  18: Step18, // user details
};
