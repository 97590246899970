import React, { useContext, createContext, useState } from "react";

const Context = createContext(null);

const AppDataProvider = ({ children }) => {
  // Normal React Component
  const [userFirebaseDB, setUserFirebaseDB] = useState("");

  const value = {
    userFirebaseDB,
    setUserFirebaseDB,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

function useAppContext() {
  //this value is the same as the value in the Context.Provider
  const value = useContext(Context);
  if (value === undefined) {
    throw new Error("useAppContext must be used within a AppDataProvider");
  }
  return value;
}

export { AppDataProvider, useAppContext };
