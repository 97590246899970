import React from "react";
import classnames from "classnames";

export const Button = ({
  children,
  className,
  onClick = undefined,
  disabled = false,
  borderRadius = "lg",
  bgColor = "bg-moss-200",
  fontSize = "sm",
  textColor = "white",
  borderWidth = "border",
  borderColor = "white",
  type = "button",
  ...rest
}) => {
  return (
    <button
      {...rest}
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classnames(
        `${className} leading-snug rounded-${borderRadius} ${
          disabled ? "bg-gray-500" : bgColor
        } text-${fontSize} text-${textColor} ${borderWidth} border-${
          disabled ? "gray-500" : borderColor
        }`,
        {
          "pointer-events-none": disabled,
        }
      )}
    >
      {children}
    </button>
  );
};
