import React from "react";
import Nicholas from "assets/Nicholas.png";

// form & validsation
import * as yup from "yup";
import { FormError, FormRadio, LM } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { customClasses } from "styles";

// schema
const Step09schema = yup.object().shape({
  marriage_cert_if_english: yup
    .string()
    .required("This is a required field")
    .nullable(),
});
export function Step09({ onSubmit, store }) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: store,
    resolver: yupResolver(Step09schema),
  });
  return (
    <form id="divorce-form" onSubmit={handleSubmit(onSubmit)}>
      <section>
        <img
          src={Nicholas}
          className="w-20 h-20 m-auto border-blue-200 border-2 rounded-full"
          alt="user"
        />

        <p className={customClasses.questionText}>
          Is the marriage certificate in english?
        </p>
        <LM>
          <p className={customClasses.helperText}>
            If it's not in english, the marriage certificate will need to be
            translated by an accredited translator. A special court document
            needs to be prepared as well.
          </p>
        </LM>
        <FormRadio
          setValue={setValue}
          value="Yes"
          name="marriage_cert_if_english"
          register={register}
        />
        <FormRadio
          setValue={setValue}
          value="No"
          name="marriage_cert_if_english"
          register={register}
        />
        <FormRadio
          setValue={setValue}
          value="Unsure"
          name="marriage_cert_if_english"
          register={register}
        />

        <FormError error={errors.marriage_cert_if_english?.message} />
      </section>
    </form>
  );
}
