import React from 'react';

export const CreditCardIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 20,
	fillColor = 'none'
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		className={`stroke-current ${strokeColor}`}
	>
		<rect
			x="48"
			y="96"
			width="416"
			height="320"
			rx="56"
			ry="56"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<line
			x1="48"
			y1="192"
			x2="464"
			y2="192"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<rect
			x="128"
			y="300"
			width="48"
			height="20"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const AccountIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 20,
	fillColor = 'none'
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		className={`stroke-current ${strokeColor}`}
	>
		<path
			d="M344,144c-3.92,52.87-44,96-88,96s-84.15-43.12-88-96c-4-55,35-96,88-96S348,90,344,144Z"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M256,304c-87,0-175.3,48-191.64,138.6C62.39,453.52,68.57,464,80,464H432c11.44,0,17.62-10.48,15.65-21.4C431.3,352,343,304,256,304Z"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const ShareIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 20,
	fillColor = 'none'
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		className={`stroke-current ${strokeColor}`}
	>
		<path
			d="M256,104v56h56a56,56,0,1,0-56-56Z"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M256,104c0,15.46,0,56,0,56H200a56,56,0,1,1,56-56Z"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<rect
			x="64"
			y="160"
			width="384"
			height="112"
			rx="32"
			ry="32"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M416,272V416a48,48,0,0,1-48,48H144a48,48,0,0,1-48-48V272"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<line
			x1="256"
			y1="160"
			x2="256"
			y2="464"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const TAndCIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 20,
	fillColor = 'none'
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		className={`stroke-current ${strokeColor}`}
	>
		<path
			d="M416,221.25V416a48,48,0,0,1-48,48H144a48,48,0,0,1-48-48V96a48,48,0,0,1,48-48h98.75a32,32,0,0,1,22.62,9.37L406.63,198.63A32,32,0,0,1,416,221.25Z"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M256,56V176a32,32,0,0,0,32,32H408"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<line
			x1="176"
			y1="288"
			x2="336"
			y2="288"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<line
			x1="176"
			y1="368"
			x2="336"
			y2="368"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const HomeIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 20,
	fillColor = 'none'
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		className={`stroke-current ${strokeColor}`}
	>
		<path
			d="M80,212V448a16,16,0,0,0,16,16h96V328a24,24,0,0,1,24-24h80a24,24,0,0,1,24,24V464h96a16,16,0,0,0,16-16V212"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M480,256,266.89,52c-5-5.28-16.69-5.34-21.78,0L32,256"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<polyline
			points="400 179 400 64 352 64 352 133"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8 20H3V10H0L10 0l10 10h-3v10h-5v-6H8v6z"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const ProfileIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 20,
	fillColor = 'none'
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		className={`stroke-current ${strokeColor}`}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M258.9,48C141.92,46.42,46.42,141.92,48,258.9,49.56,371.09,140.91,462.44,253.1,464c117,1.6,212.48-93.9,210.88-210.88C462.44,140.91,371.09,49.56,258.9,48ZM385.32,375.25a4,4,0,0,1-6.14-.32,124.27,124.27,0,0,0-32.35-29.59C321.37,329,289.11,320,256,320s-65.37,9-90.83,25.34a124.24,124.24,0,0,0-32.35,29.58,4,4,0,0,1-6.14.32A175.32,175.32,0,0,1,80,259C78.37,161.69,158.22,80.24,255.57,80S432,158.81,432,256A175.32,175.32,0,0,1,385.32,375.25Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M256,144c-19.72,0-37.55,7.39-50.22,20.82s-19,32-17.57,51.93C191.11,256,221.52,288,256,288s64.83-32,67.79-71.24c1.48-19.74-4.8-38.14-17.68-51.82C293.39,151.44,275.59,144,256,144Z"
		/>
	</svg>
);

export const CommunityIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 20,
	fillColor = 'none'
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		className={`stroke-current ${strokeColor}`}
	>
		<title>People Circle</title>
		<path
			strokeWidth={strokeWidth}
			d="M256 464c-114.69 0-208-93.31-208-208S141.31 48 256 48s208 93.31 208 208-93.31 208-208 208zm0-384c-97 0-176 79-176 176s79 176 176 176 176-78.95 176-176S353.05 80 256 80z"
		/>
		<path
			strokeWidth={strokeWidth}
			d="M323.67 292c-17.4 0-34.21-7.72-47.34-21.73a83.76 83.76 0 01-22-51.32c-1.47-20.7 4.88-39.75 17.88-53.62S303.38 144 323.67 144c20.14 0 38.37 7.62 51.33 21.46s19.47 33 18 53.51a84 84 0 01-22 51.3C357.86 284.28 341.06 292 323.67 292zm55.81-74zM163.82 295.36c-29.76 0-55.93-27.51-58.33-61.33-1.23-17.32 4.15-33.33 15.17-45.08s26.22-18 43.15-18 32.12 6.44 43.07 18.14 16.5 27.82 15.25 45c-2.44 33.77-28.6 61.27-58.31 61.27zM420.37 355.28c-1.59-4.7-5.46-9.71-13.22-14.46-23.46-14.33-52.32-21.91-83.48-21.91-30.57 0-60.23 7.9-83.53 22.25-26.25 16.17-43.89 39.75-51 68.18-1.68 6.69-4.13 19.14-1.51 26.11a192.18 192.18 0 00232.75-80.17zM163.63 401.37c7.07-28.21 22.12-51.73 45.47-70.75a8 8 0 00-2.59-13.77c-12-3.83-25.7-5.88-42.69-5.88-23.82 0-49.11 6.45-68.14 18.17-5.4 3.33-10.7 4.61-14.78 5.75a192.84 192.84 0 0077.78 86.64l1.79-.14a102.82 102.82 0 013.16-20.02z"
		/>
	</svg>
);

export const NotificationsIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 20,
	fillColor = 'none',
	classes = ''
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		className={`stroke-current ${strokeColor} ${classes}`}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M256,480a80.09,80.09,0,0,0,73.3-48H182.7A80.09,80.09,0,0,0,256,480Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M400,288V227.47C400,157,372.64,95.61,304,80l-8-48H216l-8,48c-68.88,15.61-96,76.76-96,147.47V288L64,352v48H448V352Z"
		/>
	</svg>
);

export const LogoutIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 20,
	fillColor = 'none'
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		className={`stroke-current ${strokeColor}`}
	>
		<path
			d="M320,176V136a40,40,0,0,0-40-40H88a40,40,0,0,0-40,40V376a40,40,0,0,0,40,40H280a40,40,0,0,0,40-40V336"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<polyline
			points="384 176 464 256 384 336"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<line
			x1="191"
			y1="256"
			x2="464"
			y2="256"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const CalendarIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 20,
	fillColor = 'none'
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		className={`stroke-current ${strokeColor}`}
	>
		<rect
			x="48"
			y="80"
			width="416"
			height="384"
			rx="48"
			ry="48"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M397.82,80H114.18C77.69,80,48,110.15,48,147.2V208H64c0-16,16-32,32-32H416c16,0,32,16,32,32h16V147.2C464,110.15,434.31,80,397.82,80Z"
		/>
		<circle
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			cx="296"
			cy="232"
			r="24"
		/>
		<circle
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			cx="376"
			cy="232"
			r="24"
		/>
		<circle
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			cx="296"
			cy="312"
			r="24"
		/>
		<circle
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			cx="376"
			cy="312"
			r="24"
		/>
		<circle
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			cx="136"
			cy="312"
			r="24"
		/>
		<circle
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			cx="216"
			cy="312"
			r="24"
		/>
		<circle
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			cx="136"
			cy="392"
			r="24"
		/>
		<circle
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			cx="216"
			cy="392"
			r="24"
		/>
		<circle
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			cx="296"
			cy="392"
			r="24"
		/>
		<line
			x1="128"
			y1="48"
			x2="128"
			y2="80"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<line
			x1="384"
			y1="48"
			x2="384"
			y2="80"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const EditAvatarIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 1.2,
	fillColor = 'none',
	classes = ''
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		className={`stroke-current ${strokeColor} ${classes}`}
	>
		<path
			d="M350.54 148.68l-26.62-42.06C318.31 100.08 310.62 96 302 96h-92c-8.62 0-16.31 4.08-21.92 10.62l-26.62 42.06C155.85 155.23 148.62 160 140 160H80a32 32 0 00-32 32v192a32 32 0 0032 32h352a32 32 0 0032-32V192a32 32 0 00-32-32h-59c-8.65 0-16.85-4.77-22.46-11.32z"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle cx="256" cy="272" r="80" strokeMiterlimit="10" strokeWidth={strokeWidth} />
		<path strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" d="M124 158v-22h-24v22" />
	</svg>
);

export const PeerFeedbackIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 1.2,
	fillColor = 'none',
	classes = ''
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		className={`stroke-current ${strokeColor} ${classes}`}
	>
		<path
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			d="m255.999 248.226c-10.328-10.18-23.829-15.786-38.016-15.786-18.143 0-33.239 11.895-42.043 20.862-8.62-9.016-23.526-20.862-42.34-20.862-14.186 0-27.686 5.606-38.014 15.786-10.141 9.996-16.144 23.311-16.903 37.491-.968 18.152 6.065 31.094 11.44 39.25 13.054 19.808 73.404 59.978 80.243 64.492 1.671 1.104 3.59 1.655 5.51 1.655s3.839-.552 5.51-1.654c6.814-4.499 66.95-44.529 80.086-64.504 5.372-8.172 12.4-21.132 11.43-39.24-.759-14.181-6.762-27.494-16.903-37.49zm-11.238 65.743c-7.605 11.564-44.152 38.431-68.885 55.121-24.827-16.748-61.5-43.668-69.053-55.129-6.236-9.463-8.679-17.591-8.168-27.177 1.014-18.938 16.69-34.345 34.945-34.345 18.931 0 33.734 21.693 33.865 21.889 1.826 2.751 4.894 4.424 8.196 4.469 3.276.061 6.413-1.544 8.314-4.244.156-.222 16.062-22.113 34.008-22.113 18.257 0 33.934 15.407 34.948 34.346.512 9.561-1.931 17.691-8.17 27.183z"
		/>
		<path
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			d="m506.138 171.936c-12.022-45.648-41.06-83.83-81.763-107.511-40.523-23.577-87.783-29.942-133.065-17.925-41.701 11.066-77.499 36.795-101.311 72.69-35.458-2.96-70.926 4.974-102.346 23.255-40.703 23.682-69.74 61.863-81.763 107.511-6.31 23.959-7.557 48.42-3.705 72.705 3.426 21.6 10.711 42.094 21.674 60.996l-20.306 40.85c-1.665 3.349-1.322 7.346.888 10.361 2.21 3.017 5.918 4.551 9.612 3.97l49.815-7.793c19.609 16.29 42.126 27.882 67 34.482 14.961 3.971 30.133 5.934 45.211 5.934 30.56 0 60.72-8.071 87.856-23.859 23.711-13.795 43.463-32.512 58.199-54.757 4.674.378 9.351.566 14.024.566 15.12 0 30.199-1.974 45.005-5.903 24.874-6.6 47.391-18.192 67-34.481l49.813 7.793c3.695.577 7.402-.953 9.612-3.97 2.21-3.016 2.553-7.013.889-10.361l-20.307-40.851c10.964-18.902 18.249-39.396 21.675-60.996 3.851-24.285 2.604-48.747-3.707-72.706zm-252.262 258.38c-35.9 20.887-77.764 26.525-117.879 15.882-23.404-6.211-44.447-17.401-62.545-33.262-1.836-1.609-4.183-2.48-6.59-2.48-.514 0-1.031.04-1.546.12l-35.245 5.514 14.135-28.436c1.544-3.106 1.368-6.791-.465-9.735-23.051-37.034-29.625-80.67-18.511-122.869 10.66-40.475 36.4-74.326 72.48-95.317 35.899-20.888 77.764-26.529 117.879-15.883 83.033 22.035 132.724 107.784 110.768 191.149-10.66 40.475-36.401 74.325-72.481 95.317zm214.412-130.416c-1.833 2.944-2.01 6.629-.466 9.735l14.135 28.435-35.243-5.514c-2.933-.456-5.908.406-8.137 2.359-18.098 15.859-39.141 27.05-62.546 33.262-14.058 3.729-28.387 5.473-42.736 5.207 5.177-10.536 9.341-21.672 12.401-33.293 24.76-94.009-31.305-190.714-124.978-215.572-2.844-.755-5.696-1.437-8.553-2.047 20.935-27.752 50.41-47.655 84.275-56.642 40.111-10.644 81.977-5.005 117.877 15.882 36.08 20.991 61.821 54.842 72.482 95.317 11.114 42.201 4.54 85.836-18.511 122.871z"
		/>
	</svg>
);

export const Hamburger = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 1.2,
	fillColor = 'none',
	classes = ''
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 16 16"
		className={`stroke-current ${strokeColor} ${classes}`}
	>
		<path
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
		/>
	</svg>
);

export const Call = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 1.2,
	fillColor = 'none',
	classes = ''
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 16 16"
		// className={`stroke-current ${strokeColor} ${classes}`}
	>
		<path
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
		/>
	</svg>
);

export const GoBackIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 1.2,
	fillColor = 'none'
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		className={`stroke-current ${strokeColor}`}
	>
		<path
			d="M3.828 9l6.071-6.071-1.414-1.414L0 10l.707.707 7.778 7.778 1.414-1.414L3.828 11H20V9H3.828z"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
