import React from 'react';
import classnames from 'classnames';
import { useToggle } from 'hooks';
import { PasswordHideIcon, PasswordShowIcon, WarningIcon } from '../SvgIcon';

export const FormInput = ({
	bgColor = 'gray-100',
	type = 'text',
	name,
	className,
	id,
	register,
	placeholder,
	error,
	...rest
}) => {
	return (
		<input
			{...register(name)}
			placeholder={placeholder}
			id={id}
			name={name}
			type={type}
			className={classnames(
				`${className} appearance-none border rounded-lg w-full h-12 py-2 pr-3 leading-tight focus:outline-none bg-${bgColor}`,
				{
					'border-red-500': error,
					'text-red-500': error,
					'placeholder-red-500': error,
					'focus:border-red-500': error,
					'focus:shadow-outline-red': error,
					'focus:shadow-outline': !error,
					'pl-10': !placeholder,
					'pl-4': placeholder
				}
			)}
		/>
	);
};

export const FormTextArea = ({
	bgColor = 'gray-100',
	rows = 5,
	name,
	className,
	id,
	register,
	placeholder,
	error,
	...rest
}) => {
	return (
		<textarea
			{...register(name)}
			placeholder={placeholder}
			id={id}
			name={name}
			rows={rows}
			// ref={register}
			className={classnames(
				`${className} appearance-none rounded-lg form-textarea border w-full p-2 leading-tight focus:outline-none bg-${bgColor}`,
				{
					'border-red-500': error,
					'text-red-500': error,
					'placeholder-red-500': error,
					'focus:border-red-500': error,
					'focus:shadow-outline-red': error,
					'focus:shadow-outline': !error
				}
			)}
		/>
	);
};

export const FormRadio = ({ setValue, value, name, register }) => {
	return (
		<div
			className="flex items-center justify-center px-2 mb-2 bg-gray-100 rounded-lg h-14"
			onClick={() => setValue(name, value)}
		>
			<label className="container text-gray-700 text-lg text-left leading-snug">
				<input type="radio" type="radio" value={value} id={name} name={name} {...register(name)} />
				<span class="checkmark"></span>
				{value}
			</label>
			{/* <input
				type="radio"
				value={value}
				id={name}
				name={name}
				{...register(name)}
				className="form-radio h-7 w-7 ml-2 bg-native-300 transition duration-150 ease-in-out"
			/> */}
			{/* <label className="pl-1 md:pl-3 whitespace-no-wrap block text-gray-700 text-lg text-left leading-snug">
				
			</label> */}
		</div>
	);
};

export const FormLabel = ({ htmlFor, className = 'text-gray-600', children, error, id }) => {
	return (
		<section className="relative">
			<div
				className={classnames('absolute inset-y-0 right-0 flex items-center cursor-pointer', {
					hidden: !error
				})}
			>
				<WarningIcon />
			</div>
			<label
				className={classnames(`${className} block text-left text-xs leading-snug font-light mb-2`)}
				id={id}
				htmlFor={htmlFor}
			>
				{children}
			</label>
		</section>
	);
};

export const FormPasswordInput = ({ bgColor, className, id, name, placeholder, register, error, ...rest }) => {
	const { toggleOn, onToggleClick } = useToggle();
	const renderPasswordVisibleIcon = () =>
		toggleOn ? <PasswordHideIcon strokeColor="text-gray-600" /> : <PasswordShowIcon strokeColor="text-gray-600" />;
	return (
		<>
			<div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" onClick={onToggleClick}>
				{renderPasswordVisibleIcon()}
			</div>
			<FormInput
				bgColor={bgColor}
				name={name}
				placeholder={placeholder}
				className={className}
				register={register}
				error={error}
				{...rest}
				type={toggleOn ? 'text' : 'password'}
				id={id}
			/>
		</>
	);
};

export const FormError = ({ error, id = 'form-error' }) => {
	return (
		<p
			data-cy={id}
			role="alert"
			className={classnames('mt-2 text-sm text-center text-red-500', {
				hidden: !error
			})}
		>
			{error}
		</p>
	);
};
