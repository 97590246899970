import React from "react";
import Nicholas from "assets/Nicholas.png";

// form & validsation
import * as yup from "yup";
import { FormError, FormRadio, LM } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { customClasses } from "styles";

// schema
export const Step06schema = yup.object().shape({
  reconciliation_counselling: yup
    .string()
    .required("This is a required field")
    .nullable(),
});
export function Step06({ onSubmit, store }) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: store,
    resolver: yupResolver(Step06schema),
  });
  return (
    <form id="divorce-form" onSubmit={handleSubmit(onSubmit)}>
      <section>
        <img
          src={Nicholas}
          className="w-20 h-20 m-auto border-blue-200 border-2 rounded-full"
          alt="user"
        />

        <p className={customClasses.questionText}>
          Is it feasible for you and {store?.ex_first_name} to attend
          reconciliation counselling
        </p>
        <LM>
          <p className={customClasses.helperText}>
            Because you have not obtained the certificate, we need to determine
            whether the court will exempt you from attending compulsory
            reconciliation counselling. You can obtain an exemption from
            attending this counselling session in normally the following
            circumstances:
          </p>
          <ul className="list-disc ml-5">
            <li className={customClasses.helperText}>
              You cannot locate your partner.
            </li>
            <li className={customClasses.helperText}>
              You attended counseling but your partner did not attend the
              session.
            </li>
            <li className={customClasses.helperText}>
              You are afraid for your safety.
            </li>
          </ul>
        </LM>
        <FormRadio
          setValue={setValue}
          value="Yes"
          name="reconciliation_counselling"
          register={register}
        />
        <FormRadio
          setValue={setValue}
          value="No"
          name="reconciliation_counselling"
          register={register}
        />

        <FormError error={errors.reconciliation_counselling?.message} />
      </section>
    </form>
  );
}
