import React from "react";
import Nicholas from "assets/Nicholas.png";

// form & validsation
import * as yup from "yup";
import { FormLabel, FormInput, FormError, FormRadio } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { customClasses } from "styles";

// schema
const Step03schema = yup.object().shape({
  get_married: yup.string().required("This is a required field").nullable(),
});

export function Step03({ onSubmit, store }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: store,
    resolver: yupResolver(Step03schema),
  });
  return (
    <form id="divorce-form" onSubmit={handleSubmit(onSubmit)}>
      <section>
        <img
          src={Nicholas}
          className="w-20 h-20 m-auto border-blue-200 border-2 rounded-full"
          alt="user"
        />

        <section className="pb-6 md:pb-5">
          <FormLabel
            htmlFor="get_married"
            className={customClasses.questionText}
            error={errors?.get_married?.message}
          >
            When did you get married?
          </FormLabel>
          <article>
            <FormInput
              placeholder="When do you get married"
              id="get_married"
              type="date"
              name="get_married"
              error={errors?.get_married?.message}
              register={register}
            />
          </article>
        </section>
      </section>
    </form>
  );
}
