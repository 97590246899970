import React from 'react';

export const PasswordShowIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 20,
	fillColor = 'none'
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		className={`stroke-current ${strokeColor}`}
	>
		<path
			d="M255.66,112c-77.94,0-157.89,45.11-220.83,135.33a16,16,0,0,0-.27,17.77C82.92,340.8,161.8,400,255.66,400,348.5,400,429,340.62,477.45,264.75a16.14,16.14,0,0,0,0-17.47C428.89,172.28,347.8,112,255.66,112Z"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle
			cx="256"
			cy="256"
			r="80"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const PasswordHideIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 20,
	fillColor = 'none'
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		className={`stroke-current ${strokeColor}`}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M432,448a15.92,15.92,0,0,1-11.31-4.69l-352-352A16,16,0,0,1,91.31,68.69l352,352A16,16,0,0,1,432,448Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M255.66,384c-41.49,0-81.5-12.28-118.92-36.5-34.07-22-64.74-53.51-88.7-91l0-.08c19.94-28.57,41.78-52.73,65.24-72.21a2,2,0,0,0,.14-2.94L93.5,161.38a2,2,0,0,0-2.71-.12c-24.92,21-48.05,46.76-69.08,76.92a31.92,31.92,0,0,0-.64,35.54c26.41,41.33,60.4,76.14,98.28,100.65C162,402,207.9,416,255.66,416a239.13,239.13,0,0,0,75.8-12.58,2,2,0,0,0,.77-3.31l-21.58-21.58a4,4,0,0,0-3.83-1A204.8,204.8,0,0,1,255.66,384Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M490.84,238.6c-26.46-40.92-60.79-75.68-99.27-100.53C349,110.55,302,96,255.66,96a227.34,227.34,0,0,0-74.89,12.83,2,2,0,0,0-.75,3.31l21.55,21.55a4,4,0,0,0,3.88,1A192.82,192.82,0,0,1,255.66,128c40.69,0,80.58,12.43,118.55,37,34.71,22.4,65.74,53.88,89.76,91a.13.13,0,0,1,0,.16,310.72,310.72,0,0,1-64.12,72.73,2,2,0,0,0-.15,2.95l19.9,19.89a2,2,0,0,0,2.7.13,343.49,343.49,0,0,0,68.64-78.48A32.2,32.2,0,0,0,490.84,238.6Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M256,160a95.88,95.88,0,0,0-21.37,2.4,2,2,0,0,0-1,3.38L346.22,278.34a2,2,0,0,0,3.38-1A96,96,0,0,0,256,160Z"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M165.78,233.66a2,2,0,0,0-3.38,1,96,96,0,0,0,115,115,2,2,0,0,0,1-3.38Z"
		/>
	</svg>
);

export const EmailIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 20,
	fillColor = 'none'
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		className={`stroke-current ${strokeColor}`}
	>
		<rect
			x="48"
			y="96"
			width="416"
			height="320"
			rx="40"
			ry="40"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<polyline
			points="112 160 256 272 400 160"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const PasswordLockIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 20,
	fillColor = 'none'
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		className={`stroke-current ${strokeColor}`}
	>
		<path
			d="M336,208V113a80,80,0,0,0-160,0v95"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<rect
			x="96"
			y="208"
			width="320"
			height="272"
			rx="48"
			ry="48"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const CheckmarkIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 20,
	fillColor = 'none'
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		className={`stroke-current ${strokeColor}`}
	>
		<path
			d="M448,256c0-106-86-192-192-192S64,150,64,256s86,192,192,192S448,362,448,256Z"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<polyline
			points="352 176 217.6 336 160 272"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
export const CloseCircleIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 1.2,
	fillColor = 'none',
	classes = ''
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		className={`stroke-current ${strokeColor} ${classes}`}
	>
		<path
			d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M320 320L192 192M192 320l128-128"
		/>
	</svg>
);

export const WarningIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-red-500',
	strokeWidth = 1.2,
	fillColor = '#FF0033'
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		className={`stroke-current ${strokeColor}`}
	>
		<path
			fillRule="evenodd"
			d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
			clipRule="evenodd"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const GoForwardIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 1.2,
	fillColor = 'none'
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		className={`stroke-current ${strokeColor}`}
	>
		<polygon
			points="16.172 9 10.101 2.929 11.515 1.515 20 10 19.293 10.707 11.515 18.485 10.101 17.071 16.172 11 0 11 0 9"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const GoBackIcon = ({
	width = 20,
	height = 20,
	strokeColor = 'text-black',
	strokeWidth = 1.2,
	fillColor = 'none'
}) => (
	<svg
		width={width}
		height={height}
		fill={fillColor}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		className={`stroke-current ${strokeColor}`}
	>
		<path
			d="M3.828 9l6.071-6.071-1.414-1.414L0 10l.707.707 7.778 7.778 1.414-1.414L3.828 11H20V9H3.828z"
			strokeWidth={strokeWidth}
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
