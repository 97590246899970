import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { useToggle } from "hooks";
export function LM({ children }) {
  const { toggleOn, onToggleClick } = useToggle();
  return (
    <section className=" flex flex-col items-center mt-4 mb-4 w-full justify-self-center justify-items-center  ">
      <span
        onClick={onToggleClick}
        className="  text-native-200 text-md font-medium items-center cursor-pointer"
      >
        Learn more {toggleOn ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </span>
      {toggleOn ? <>{children} </> : null}
    </section>
  );
}
