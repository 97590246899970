import React from 'react';
import styled from 'styled-components';
import { CloseCircleIcon } from 'components';
import Zoom from 'react-reveal/Zoom';

const ModalBody = styled.section`
	max-height: 85vh;
`;
export function Modal({ children, toggleOn, onToggleClick }) {
	return toggleOn ? (
		<div className="fixed bottom-0 z-50 inset-x-0 px-4 pb-6 inset-0 p-0 flex items-center justify-center">
			<div className="fixed inset-0 transition-opacity">
				<div className="absolute inset-0 bg-gray-100 opacity-75" />
			</div>

			<ModalBody className="bg-white rounded-lg p-5 md:p-8 overflow-y-auto shadow-xl transform transition-all w-full md:max-w-2xl opacity-100">
				<section onClick={onToggleClick} role="button" className="absolute top-0 right-0 flex pr-1 pt-1">
					<section className="text-black">
						<CloseCircleIcon strokeWidth={35} classes="mx-auto w-6 h-6 text-gray-400" />
					</section>
				</section>
				<section className="pt-8 md:pt-12 lg:pt-10">{children}</section>
			</ModalBody>
		</div>
	) : null;
}
