import React from "react";
import Nicholas from "assets/Nicholas.png";

// form & validsation
import * as yup from "yup";
import { FormLabel, FormInput, FormError } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { customClasses } from "styles";

// schema
const Step18schema = yup
  .object()
  .shape({
    details: yup
      .object()
      .shape({
        first_name: yup.string().required("This is a required field"),
        last_name: yup.string().required("This is a required field"),
        phone: yup.string().required("This is a required field"),
        email: yup
          .string()
          .email("incorrect email")
          .required("This is a required field"),
      })
      .required(),
  })
  .required();

export function Step18({ onSubmit, store }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: store,
    resolver: yupResolver(Step18schema),
  });
  return (
    <form id="divorce-form" onSubmit={handleSubmit(onSubmit)}>
      <section>
        <img
          src={Nicholas}
          className="w-20 h-20 m-auto border-blue-200 border-2 rounded-full"
          alt="user"
        />

        <p className={customClasses.questionText}>
          Enter your details and we will email your quote to you shortly.
        </p>
        <section className="pb-6 md:pb-5">
          <FormLabel
            htmlFor="details.first_name"
            className="text-sm md:text-base text-black"
            error={errors?.details?.first_name?.message}
          >
            First Name *
          </FormLabel>
          <article className="grid gap-y-2 grid-cols-1 md:flex">
            <FormInput
              placeholder="First Name"
              id="details.first_name"
              type="text"
              name="details.first_name"
              error={errors?.details?.first_name?.message}
              register={register}
              className="mt-4"
            />
          </article>
          <FormError error={errors?.details?.first_name?.message} />
        </section>
        <section className="pb-6 md:pb-5">
          <FormLabel
            htmlFor="details.last_name"
            className="text-sm md:text-base text-black"
            error={errors?.details?.last_name?.message}
          >
            Last Name *
          </FormLabel>
          <article className="grid gap-y-2 grid-cols-1 md:flex">
            <FormInput
              placeholder="Last Name"
              id="details.last_name"
              type="text"
              name="details.last_name"
              error={errors?.details?.last_name?.message}
              register={register}
              className="mt-4"
            />
          </article>
          <FormError error={errors?.details?.last_name?.message} />
        </section>

        <section className="pb-6 md:pb-5">
          <FormLabel
            htmlFor="details.last_name"
            className="text-sm md:text-base text-black"
            error={errors?.details?.last_name?.message}
          >
            Your Mobile Number *
          </FormLabel>
          <article className="grid gap-y-2 grid-cols-1 md:flex">
            <FormInput
              placeholder="0411 111 111"
              id="details.phone"
              type="text"
              name="details.phone"
              error={errors?.details?.phone?.message}
              register={register}
              className="mt-4"
            />
          </article>
          <FormError error={errors?.details?.phone?.message} />
        </section>

        <section className="pb-6 md:pb-5">
          <FormLabel
            htmlFor="details.email"
            className="text-sm md:text-base text-black"
            error={errors?.details?.email?.message}
          >
            Email Address *
          </FormLabel>
          <article className="grid gap-y-2 grid-cols-1 md:flex">
            <FormInput
              placeholder="Email Address"
              id="details.email"
              type="text"
              name="details.email"
              error={errors?.details?.email?.message}
              register={register}
              className="mt-4"
            />
          </article>
          <FormError error={errors?.details?.email?.message} />
        </section>
      </section>
    </form>
  );
}
