import { useEffect } from 'react';
import { DivorceForm } from 'features/DivorceForm';
// import { anonymousLogin, getDocument } from 'services';
import { AppDataProvider, useAppContext } from 'store';
import { Loader } from 'components/Basic';
import React from 'react';
import './App.css';
// function ContextHelper() {
// 	const { userFirebaseDB, setUserFirebaseDB } = useAppContext();

// 	const [spinnerState, setSpinnerState] = React.useState(false);

// 	React.useEffect(() => {
// 		getUserData();
// 		//you can't put async function in useffect as it complicates the useEffect hook. one function does one thing. DRY
// 	}, []);

// 	const functionListner = data => {
// 		console.log('data', data);
// 		if (data) setUserFirebaseDB(data);
// 		setSpinnerState(false);
// 	};

// 	const getUserData = async () => {
// 		setSpinnerState(true);
// 		//start here with listerner.
// 		getDocument(functionListner);
// 	};

// 	return (
// 		<>
// 			{spinnerState && (
// 				<section className="w-full h-screen absolute z-10 bg-white bg-opacity-40 flex items-center justify-center">
// 					<Loader />
// 				</section>
// 			)}
// 		</>
// 	);
// }

function App() {
	// const init = async () => {
	// 	anonymousLogin();
	// };
	//<ContextHelper />
	return (
		<AppDataProvider>
			<DivorceForm />
		</AppDataProvider>
	);
}

export default App;
