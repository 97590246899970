// firebase/ firestore
import firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

// saves to firesbase
const saveToFirebase = async (props, setCurrentStep, onToggleClick) => {
  const db = firebase.firestore();
  console.log("saveToFirebase-props", props, "email", props?.details?.email);
  db.collection("divorce_quote_web")
    .doc(props?.details?.email)
    .set({
      ...props,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then(() => {
      console.log("Document successfully written!");
      setCurrentStep(0);
      // onToggleClick();
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
    });
};

const getDocument = async (func) => {
  const dbh = firebase.firestore();
  const doc = await dbh
    .collection("divorce_quote_web")
    .doc("reference_no")
    .get();

  if (doc.exists) {
    func(doc.data());
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    func({ last_ref_no: 2000 });
  }
};

const userListener = async (func) => {
  const db = firebase.firestore();

  db.collection("divorce_quote_web")
    .doc("GJMEhETsqakzBqq0MKC9")
    .onSnapshot(
      (doc) => {
        func(doc.data());
        // return doc.data();
      },
      (error) => {
        // ...
      }
    );
};

//custom cloud functions . sends confirmation email to mki legal
const sendMkiEmail = async (data) => {
  const addMessage = firebase.functions().httpsCallable("sendMkiEmail");
  return await addMessage(data);
};

//custom cloud functions . sends confirmation email to User after submission
const sendUserConfirmationEmail = async (data) => {
  const addMessage = firebase
    .functions()
    .httpsCallable("sendUserConfirmationEmail");
  return await addMessage(data);
};

export {
  saveToFirebase,
  getDocument,
  userListener,
  sendMkiEmail,
  sendUserConfirmationEmail,
};
