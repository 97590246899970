import React from 'react';
import Nicholas from 'assets/Nicholas.png';
//my components

import { useForm } from 'react-hook-form';

export function Step00({ onSubmit, store }) {
	const {
		handleSubmit,
		register,
		formState: { errors }
	} = useForm({
		defaultValues: store
	});

	// const function1 = (formData) => {
	//  onSubmit(formData)
	// }

	return (
		// lesson <form id="divorce-form" onSubmit={handleSubmit}>
		<form id="divorce-form" onSubmit={handleSubmit(onSubmit)}>
			<img src={Nicholas} className="w-20 h-20 m-auto border-blue-200 border-2 rounded-full" alt="user" />
			<p className="font-medium text-lg text-center mt-4">
				Get a free quote to finalise your divorce. Takes 2 minutes.
			</p>
			<p className="font-medium text-lg text-center mt-4">
				We will ask you a few quick questions. Then we will email you the quote.
			</p>
			<p className="font-medium text-lg text-center mt-4">Free. Confidential. No obligation. </p>
		</form>
	);
}
