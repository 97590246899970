import React from "react";
import Nicholas from "assets/Nicholas.png";
//my components
import { FormInput, FormLabel, LM } from "components";
// form & validsation
import * as yup from "yup";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { customClasses } from "styles";

// schema
const Step01schema = yup.object().shape({
  ex_first_name: yup.string().required("This is a required field").nullable(),
  ex_last_name: yup.string().required("This is a required field").nullable(),
});

export function Step01({ onSubmit, store }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: store,
    resolver: yupResolver(Step01schema),
  });

  // const function1 = (formData) => {
  //  onSubmit(formData)
  // }

  return (
    // lesson <form id="divorce-form" onSubmit={handleSubmit}>
    <form id="divorce-form" onSubmit={handleSubmit(onSubmit)}>
      <section>
        <img
          src={Nicholas}
          className="w-20 h-20 m-auto border-blue-200 border-2 rounded-full"
          alt="user"
        />
        <div className={customClasses.questionText}>
          What's your ex-partner's name?
        </div>
        <LM>
          <p className="text-md text-gray-700">
            We need this to personalise your experience. We will not contact
            your former partner.
          </p>
        </LM>
        <section className="pb-6 md:pb-5">
          <FormLabel
            htmlFor="ex_first_name"
            className="text-sm md:text-base text-black"
            error={errors?.ex_first_name?.message}
          >
            Ex-Partner's First Name *
          </FormLabel>
          <FormInput
            placeholder="First Name"
            id="ex_first_name"
            type="text"
            name="ex_first_name"
            error={errors?.ex_first_name?.message}
            register={register}
            className="mt-4"
          />
        </section>
        <section className="pb-6 md:pb-5">
          <FormLabel
            htmlFor="ex_last_namee"
            className="text-sm md:text-base text-black"
            error={errors?.ex_last_name?.message}
          >
            Ex-Partner's Last Name *
          </FormLabel>
          <FormInput
            placeholder="Surname"
            id="ex_last_name"
            type="text"
            name="ex_last_name"
            error={errors?.ex_last_name?.message}
            register={register}
            className="mt-4"
          />
        </section>
      </section>
    </form>
  );
}
