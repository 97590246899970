import React from "react";
import Nicholas from "assets/Nicholas.png";

// form & validsation
import * as yup from "yup";
import { FormError, FormRadio } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { customClasses } from "styles";

// schema
const Step07schema = yup.object().shape({
  marriage_less2_counselling_excuse: yup
    .string()
    .required("This is a required field")
    .nullable(),
});

export function Step07({ onSubmit, store }) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: store,
    resolver: yupResolver(Step07schema),
  });
  return (
    <form id="divorce-form" onSubmit={handleSubmit(onSubmit)}>
      <section>
        <img
          src={Nicholas}
          className="w-20 h-20 m-auto border-blue-200 border-2 rounded-full"
          alt="user"
        />

        <p className={customClasses.questionText}>
          Why can't you attend counselling with {store?.ex_first_name}
        </p>
        <FormRadio
          setValue={setValue}
          value="I tried counselling but partner did not attend"
          name="marriage_less2_counselling_excuse"
          register={register}
        />
        <FormRadio
          setValue={setValue}
          value="I cannot locate my partner"
          name="marriage_less2_counselling_excuse"
          register={register}
        />
        <FormRadio
          setValue={setValue}
          value="I am concerned for my safety"
          name="marriage_less2_counselling_excuse"
          register={register}
        />
        <FormRadio
          setValue={setValue}
          value="Other reason"
          name="marriage_less2_counselling_excuse"
          register={register}
        />

        <FormError error={errors.marriage_less2_counselling_excuse?.message} />
      </section>
    </form>
  );
}
